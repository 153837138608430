<template>
  <div class="connect-wallet-button">
    <button type="button" class="btn btn-primary" @click="handleConnect" v-if="!isConnected">
      <span class="bolinha mr-1 d-none"></span>
      Connect Wallet
    </button>
    <div class="d-flex align-items-center justify-content-center" v-else>
      <span class="bolinha connected"></span>
      <span>{{ accountAddress.substring(0, 10).toLowerCase() }}</span>
      <button type="button" class="btn ml-2 close-wallet" @click="handleDisconnect">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ConnectWalletButton',
  computed: {
    ...mapState([
      'accountAddress'
    ]),
    ...mapGetters([
      'isConnected'
    ])
  },
  methods: {
    handleConnect() {
      this.$wallet.connect()
      this.$emit('walletConnected')
    },
    handleDisconnect() {
      this.$wallet.disconnect()
      this.$emit('walletDisconnected')
      window.location.reload()
    }
  }
}
</script>

<style scoped>
  .connect-wallet-button,
  .connect-wallet-button > a {
    width: 100%;
  }

  .connect-wallet-button {
    margin-right: 20px;
  }

  .bolinha {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: red;
    margin-right: 8px;
  }

  .bolinha.connected {
    background-color: #5bd65b;
  }

  .close-wallet:hover {
    color: var(--color-body);
  }

  a {
    background-color: white;
    color: black;
    border: 0;
    padding: 18px 8px;
    white-space: normal;
    line-height: 1.3;
  }

  span {
    font-weight: normal;
    font-size: 18px;
  }

  span,
  button {
    color: var(--color-body);
  }

  .close-wallet {
    border: 0;
    padding: 3px 6px;
  }

  @media (min-width: 992px) {
    .connect-wallet-button {
      margin-right: 0;
    }
  }
</style>

import BaseContractService from "./baseContractService";
import web3 from "./web3Service";
import abi from "../abi/main.json";
import config from "../config";
import store from "../store";

class MainContractService extends BaseContractService {
  constructor() {
    super(config.mainContractAddress, abi);

    setInterval(() => {
      this._setTotalSupply();
    }, 5000);

    this._setTotalSupply();
  }

  _setTotalSupply() {
    this.totalSupply().then((total) => {
      store.commit("SET_TOTAL_SUPPLY", parseInt(total));
    });
  }

  async mint(quantity, from, maxMints, signature) {
    const mintAction = this.contract.methods.mint(
      quantity,
      maxMints,
      signature
    );

    let options = {
      from,
    };

    const gas = await mintAction
      .estimateGas(options)
      .then((e) => e)
      .catch(() => {
        throw { message: `Insufficient funds!` };
      });
    options = { ...options, gas: parseInt(1.2 * gas) };

    return mintAction.send(options);
  }

  balanceOf(address) {
    return this.contract.methods.accountToMinted(address).call();
  }

  hasMintedFreePass(address) {
    return this.contract.methods._addressToMintedFree(address).call();
  }

  totalSupply() {
    return this.contract.methods.totalSupply().call();
  }

  hasSaleStarted() {
    return this.contract.methods.hasSaleStarted().call();
  }

  hasPreSaleStarted() {
    return this.contract.methods.hasPreSaleStarted().call();
  }
}

export default new MainContractService();

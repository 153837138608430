import web3 from '../services/web3Service'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { WalletLink } from 'walletlink'
import store from '../store'
import { showSnackbar } from './SnackBar'

class Wallet {
  constructor() {
    const infuraId = 'bb11fef2f40140249c5c92ef8715117d'

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId,
        },
      },
      'custom-walletlink': {
        display: {
          logo: '/static/img/wallet-link.svg',
          name: 'WalletLink',
          description: 'Scan with WalletLink to connect',
        },
        options: {
          appName: process.env.VUE_APP_NAME,
          networkUrl: `https://mainnet.infura.io/v3/${infuraId}`,
          chainId: process.env.VUE_APP_NETWORK_ID,
        },
        package: WalletLink,
        connector: async (_, options) => {
          const { appName, networkUrl, chainId } = options

          const walletLink = new WalletLink({
            appName
          })

          const provider = walletLink.makeWeb3Provider(networkUrl, chainId)

          await provider.enable()

          return provider
        }
      }
    }

    this._web3Modal = new Web3Modal({
      network: 'mainnet',
      cacheProvider: true,
      providerOptions
    })

    this.connect()
  }

  async connect() {
    const provider = await this._web3Modal.connect()

    web3.setProvider(provider)

    provider.on('accountsChanged', this._onAccountsChanged.bind(this))
    provider.on('chainChanged', this._onChainChanged.bind(this))

    await Promise.all([
      this._getCurrentNetworkId(),
      this._getCurrentAccount()
    ])
  }

  disconnect() {
    this._web3Modal.clearCachedProvider()
  }

  async _getCurrentNetworkId() {
    const networkId = await web3.eth.getChainId()

    store.commit('SET_NETWORK_ID', networkId)

    if (networkId != process.env.VUE_APP_NETWORK_ID) {
      showSnackbar('The selected network is invalid. Please select Ethereum mainnet and try again.', 'danger')
    }
  }

  async _getCurrentAccount() {
    const accounts = await web3.eth.getAccounts()

    if (accounts.length) {
      store.commit('SET_ACCOUNT_ADDRESS', accounts[0])
    }
  }

  _onAccountsChanged(accounts) {
    if (accounts.length) {
      store.commit('SET_ACCOUNT_ADDRESS', accounts[0])
    } else {
      store.commit('SET_ACCOUNT_ADDRESS', null)
      this.disconnect()
    }
  }

  _onChainChanged(networkId) {
    store.commit('SET_NETWORK_ID', parseInt(networkId))

    if (parseInt(networkId) != process.env.VUE_APP_NETWORK_ID) {
      showSnackbar('The selected network is invalid. Please select Ethereum mainnet and try again.', 'danger')
    }
  }
}

export default {
  install(Vue) {
    Vue.prototype.$wallet = new Wallet()
  }
}

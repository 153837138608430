<template>
  <div :class="`d-flex align-items-center justify-content-center${show ? ' show' : ''}`" @click="backToTop">
    <img src="/static/img/arrow-top.svg" alt="Back to top">
  </div>
</template>

<script>
export default {
  name: 'BackToTop',
  data() {
    return {
      show: false
    }
  },
  methods: {
    backToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', e => {
      this.show = window.scrollY > 2500
    })
  }
}
</script>

<style scoped>
  div {
    position: fixed;
    bottom: 36px;
    right: 0;
    background-color: black;
    /* border-radius: 48px; */
    display: block;
    width: 48px;
    height: 48px;
    cursor: pointer;
    z-index: 3;
    visibility: hidden;
    opacity: 0;
    transition: visibility 200ms, opacity 200ms, right 200ms, background-color 200ms;
  }

  div:hover {
    background-color: rgba(0, 0, 0, .8);
  }

  img {
    height: 20px;
  }

  .show {
    opacity: 1;
    visibility: visible;
    right: 36px;
  }

  @media (min-width: 576px) {
    div {
      bottom: 56px;
    }
  }
</style>

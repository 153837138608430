import Vue from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'
import Wallet from './plugins/Wallet'
import SnackBar from './plugins/SnackBar'
import config from './config'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

Vue.config.productionTip = false

Vue.use(Wallet)
Vue.use(SnackBar)

Vue.mixin({
  computed: {
    config() {
      return config
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accountAddress: null,
    networkId: null,
    totalSupply: 0,
    snackbars: [],
  },
  getters: {
    isConnected(state, getters) {
      return state.accountAddress
        && getters.isValidNetworkId
    },
    isValidNetworkId(state) {
      return state.networkId == process.env.VUE_APP_NETWORK_ID
    }
  },
  mutations: {
    SET_ACCOUNT_ADDRESS(state, payload) {
      state.accountAddress = payload
    },
    SET_NETWORK_ID(state, payload) {
      state.networkId = payload
    },
    SET_TOTAL_SUPPLY(state, payload) {
      state.totalSupply = payload
    },
    PUSH_SNACKBAR(state, snackbar) {
      state.snackbars.push(snackbar)
    },
    POP_SNACKBAR(state, snackbar) {
      state.snackbars = state.snackbars.filter(sb => sb.data.id != snackbar.data.id)
    }
  },
  actions: {
  },
  modules: {
  }
})

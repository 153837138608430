<template>
  <div class="mint-section">
    <v-connect-wallet-button
      v-if="networkId === null || isValidNetworkId"
      class="mt-5 mb-4"
    />
    <span v-if="accountAddress && !isValidNetworkId">
      The selected network is invalid.<br />Please select Ethereum mainnet and
      try again.
    </span>
    <span v-else-if="isConnected && !contractSignature && !loading">
      You have no GENAI
    </span>
    <div class="text-center" v-else-if="loading">
      <img src="/static/img/loader.svg" alt="Loader" width="100" />
    </div>
    <template v-else-if="isConnected && contractSignature">
      <div
        class="d-flex justify-content-center align-items-center w-100"
        v-if="!submitting"
      >
        <div class="w-100">
          <div
            :class="`text-center alert alert-success ${success ? '' : 'hide'}`"
          >
            <p class="mb-0">
              You have minted {{ lastMintQuantity }} Resurrectable (s)
            </p>
          </div>
          <p class="d-block text-center" v-if="!hasFreeMint">
            How many Resurrectables do you want to mint? <br />You have
            {{ available }}
            available
          </p>
          <div class="row justify-content-center" v-if="available > 0">
            <div class="col-12">
              <input
                v-model="form.quantity"
                type="number"
                step="1"
                min="1"
                :max="available"
                class="form-control quantity-field mb-3"
              />
            </div>
          </div>
          <div v-if="available > 0" class="text-center">
            <button
              type="button"
              class="btn btn-primary"
              @click="handleSubmit"
              :disabled="submitting || available === 0"
            >
              MINT NOW
            </button>
          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <p>
          Please confirm your transaction <br />to mint your Resurrectable (s)!
        </p>
        <img src="/static/img/loader.svg" alt="Loader" width="100" />
      </div>
    </template>
    <div class="d-block mt-4" v-if="isValidNetworkId">
      {{ totalSupply }} of
      {{ Intl.NumberFormat("en-US").format(config.totalUnits) }}
      <small class="d-block">
        minted
      </small>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import web3 from "../services/web3Service.js";
import mainContractService from "../services/mainContractService.js";
import VConnectWalletButton from "../components/ConnectWalletButton.vue";

export default {
  name: "MintSection",
  components: {
    VConnectWalletButton,
  },
  data() {
    return {
      max: 10,
      loading: false,
      submitting: false,
      success: false,
      currentPrice: web3.utils.toWei(
        `${process.env.VUE_APP_UNIT_PRICE}`,
        "ether"
      ),
      form: {
        quantity: 1,
      },
      contractSignature: null,
      hasFreeMint: false,
      hasFreeMintFromAPI: false,
      hasNoMint: false,
      lastMintQuantity: 0,
      balanceOf: 0,
    };
  },
  computed: {
    ...mapState(["accountAddress", "totalSupply", "networkId"]),
    ...mapGetters(["isConnected", "isValidNetworkId"]),
    available() {
      return this.max - this.balanceOf < 0 ? 0 : this.max - this.balanceOf;
    },
    total() {
      return (
        this.form.quantity *
        web3.utils.fromWei(this.currentPrice.toString(), "ether")
      );
    },
  },
  watch: {
    "form.quantity"(v) {
      if (v > this.max) {
        this.form.quantity = this.max;
      } else if (v < 1) {
        this.form.quantity = 1;
      }
    },
    success(v) {
      if (v) {
        setTimeout(() => {
          this.success = false;
        }, 5000);
      }
    },
    accountAddress(v) {
      this.contractSignature = null;
      this.hasFreeMint = false;
      this.hasFreeMintFromAPI = false;
      this.lastMintQuantity = 0;

      if (v) {
        this.getSignature();
      }
    },
    isConnected(v) {
      if (v) {
        this.getSignature();
      }
    },
  },
  methods: {
    getSignature() {
      this.loading = true;
      Promise.all([
        axios.post(`${this.config.baseUrl}/resurrect/request`, null, {
          headers: {
            address: this.accountAddress,
          },
        }),
        mainContractService.balanceOf(this.accountAddress),
      ])
        .then(
          ([
            {
              data: { signature, maxMints },
            },
            balance,
          ]) => {
            this.contractSignature = signature;
            this.max = maxMints;
            this.balanceOf = balance;
            this.loading = false;
          }
        )
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async handleSubmit() {
      if (this.form.quantity >= 1) {
        this.submitting = true;

        try {
          await mainContractService.mint(
            this.form.quantity,
            this.accountAddress,
            this.max,
            this.contractSignature
          );
          this.lastMintQuantity = this.form.quantity;
          this.success = true;
          this.form.quantity = 1;
          mainContractService.balanceOf(this.accountAddress).then((balance) => {
            this.balanceOf = balance;
          });
          this.$showSnackbar(
            `You've minted ${this.lastMintQuantity} successfully.`,
            "success"
          );
        } catch (err) {
          this.$showSnackbar(err.message, "danger");
        } finally {
          this.submitting = false;
        }
      } else {
        this.$showSnackbar("The quantity must be greater than zero.", "danger");
      }
    },
  },
};
</script>

<style scoped>
input[type="number"] {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.quantity-field {
  text-align: center;
  width: 100px;
}
</style>

<template>
  <div id="app" class="d-flex flex-column">
    <router-view />
    <v-back-to-top />
    <v-snackbar-container />
    <div class="footer-space"></div>
  </div>
</template>

<script>
import VBackToTop from './components/BackToTop.vue'
import VSnackbarContainer from './components/SnackbarContainer.vue'

export default {
  name: 'App',
  components: {
    VBackToTop,
    VSnackbarContainer
  }
}
</script>

<style scoped>
  #app {
    min-height: 100vh;
  }

  .footer-space {
    height: 96px;
  }
</style>

export default {
  appName: process.env.VUE_APP_NAME,
  baseUrl: process.env.VUE_APP_BASE_URL,
  mainContractAddress: process.env.VUE_APP_MAIN_CONTRACT_ADDRESS,
  genainContractAddress: process.env.VUE_APP_GENAI_CONTRACT_ADDRESS,
  networkId: process.env.VUE_APP_NETWORK_ID,
  discordUrl: process.env.VUE_APP_DISCORD_URL,
  twitterUrl: process.env.VUE_APP_TWITTER_URL,
  instagramUrl: process.env.VUE_APP_INSTAGRAM_URL,
  openseaUrl: process.env.VUE_APP_OPENSEA_URL,
  releaseDate: process.env.VUE_APP_RELEASE_DATE,
  totalUnits: process.env.VUE_APP_TOTAL_UNITS,
  unitPrice: process.env.VUE_APP_UNIT_PRICE,
};

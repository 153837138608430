<template>
  <div class="home-screen">
    <div class="d-flex flex-column flex-xl-row align-items-center">
      <div
        class="presentation container text-center text-md-left px-xl-5 mt-5 mt-xl-0"
      >
        <a href="http://aiarthouse.com/" target="_blank">
          <img src="/static/img/genAI.png" alt="Logo" width="148" />
        </a>
        <h2 class="mt-0">Creativity Meets Innovation</h2>
        <p>
          Resurrectables are digital collectibles that bring legendary dead
          musicians back to life using Artificial Intelligence. This kind of
          technology is on the cutting edge of AI research, and these
          collectibles are truly the first of their kind. This is the genesis
          drop of Resurrectables, and is only available (for free) to GEN AI
          members
          <a href="https://opensea.io/collection/genai-nft" target="_blank">
            opensea link</a
          >. <br />More drops to come in the future.
        </p>
        <a href="https://twitter.com/GENAI_NFT" target="_blank">
          <img
            src="/static/img/twitter-app.png"
            alt="Follow us on Twitter"
            width="59"
          />
        </a>
        <a href="https://discord.gg/QN3zJzzpsp" target="_blank">
          <img
            src="/static/img/discord.png"
            alt="Join our channel on Discord"
            width="77"
            class="mt-2"
          />
        </a>
        <div class="mt-3 text-center">
          <v-mint-section />
        </div>
      </div>
      <div class="art-video" ref="art-video">
        <video
          autoplay="1"
          loop="1"
          muted="1"
          playsinline="1"
          :class="videoClass"
        >
          <source src="/static/videos/resurrect.mp4" />
          <source src="/static/videos/resurrect.webm" />
        </video>
        <div
          class="marketing-section d-flex flex-column flex-sm-row align-items-center justify-content-center gap-3"
        >
          <div class="d-flex align-items-center justify-content-center gap-3">
            <img
              src="/static/img/1200px-T3_magazine_brand_logo.svg.png"
              alt="T3"
              height="30"
            />
            <img
              src="/static/img/Borealis_Uncrate.png"
              alt="uncrate"
              height="30"
            />
          </div>
          <div class="d-flex align-items-center justify-content-center gap-3">
            <img
              src="/static/img/AIArtHouse-logo.png"
              alt="AI Art"
              width="146"
            />
            <img
              src="/static/img/DHfounders.png"
              alt="DeadHeads Founders"
              width="200"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VMintSection from "../components/MintSection.vue";

export default {
  name: "Home",
  components: {
    VMintSection,
  },
  data() {
    return {
      videoClass: "fullheight",
    };
  },
  computed: {
    ...mapGetters(["isConnected"]),
  },
  methods: {
    handleWindowResize() {
      const { clientWidth, clientHeight } = this.$refs["art-video"];

      if (clientWidth > clientHeight) {
        this.videoClass = "fullwidth";
      } else {
        this.videoClass = "fullheight";
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
};
</script>

<style scoped>
.specs-card {
  background-color: white;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
}

.specs-card p {
  font-size: 48px;
  line-height: 1;
  margin-bottom: 0;
}

.section-title {
  position: relative;
}

/* .section-title::before {
    text-decoration: underline;
    font-size: 26px;
    margin-top: 6px;
    left: 0;
    position: absolute;
  } */

/* .section-title:nth-child(1)::before {
    content: '01';
  }

  .section-title:nth-child(4)::before {
    content: '02';
  }

  .section-title:nth-child(6)::before {
    content: '03';
  }

  .section-title:nth-child(9)::before {
    content: '04';
  }

  .section-title:nth-child(17)::before {
    content: '05';
  }

  .section-title:nth-child(22)::before {
    content: '06';
  } */

.presentation,
.art-video {
  width: 100%;
}

.presentation {
  order: 1;
}

.art-video {
  overflow: hidden;
  height: 360px;
  position: relative;
  align-self: stretch;
  border-left: 1px solid white;
}

.art-video video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.art-video video.fullwidth {
  width: 100%;
}

.art-video video.fullheight {
  height: 100vh;
}

.marketing-section {
  position: absolute;
  bottom: 24px;
  width: 100%;
}

.marketing-section > div:first-child {
  order: 1;
}

@media (min-width: 576px) {
  /* .section-title::before {
      font-size: 32px;
    } */

  .marketing-section > div:first-child {
    order: initial;
  }
}

@media (min-width: 768px) {
  .art-video {
    height: 600px;
  }

  .marketing-section {
    bottom: 48px;
  }
}

@media (min-width: 1200px) {
  .presentation {
    order: initial;
  }

  .art-video {
    height: 100vh;
  }
}
</style>
